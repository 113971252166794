/* New Style */
.profile-sidebar {
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    margin-bottom: 30px;
    overflow: hidden;
  }
  
  @media only screen and (max-width: 767.98px) {
    .profile-sidebar {
      margin-bottom: 20px;
    }
  }
  
  .pro-widget-content {
    padding: 0 0 20px;
  }
  
  .booking-date li {
    font-size: .875rem;
  }
  
  .booking-date li span {
    font-size: .875rem;
  }
  
  .widget-profile .profile-info-widget .booking-doc-img {
    padding: 0;
  }
  
  .booking-doc-info .booking-doc-img {
    width: 80px;
    margin-right: 15px;
  }
  
  .booking-doc-info .booking-doc-img img {
    border-radius: 4px;
    height: 80px;
    width: 80px;
    object-fit: cover;
  }
  
  .widget-profile .profile-info-widget .booking-doc-img img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
  }
  
  .widget-profile {
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .widget-profile .profile-info-widget {
    display: block;
    text-align: center;
  }
  
  .widget-profile .profile-info-widget .booking-doc-img {
    display: inline-block;
    margin: 0 0 15px;
    width: auto;
    padding: 8px;
    background-color: #f7f7f7;
    border-radius: 50%;
  }
  
  .widget-profile .profile-info-widget .booking-doc-img img {
    border-radius: 50%;
    height: 120px;
    width: 120px;
  }
  
  .booking-doc-img {
    width: 75px;
  }
  
  .booking-doc-img img {
    height: 75px;
    width: 75px;
  }
  
  .booking-doc-img {
    width: 70px;
  }
  
  .booking-doc-img img {
    height: 70px;
    width: 70px;
  }
  
  .profile-info-widget .booking-doc-img {
    margin: 0 0 15px;
  }
  
  .profile-info-widget .booking-doc-img img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
  }
  
  .booking-doc-img {
    width: 80px;
    margin-right: 15px;
  }
  
  .pro-widget-content {
    border-bottom: 1px solid #f0f0f0;
    padding: 20px;
    text-align: center;
  }
  
  .profile-info-widget {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
  }
  
  .profile-info-widget .booking-doc-img {
    margin-right: 15px;
  }
  
  .profile-info-widget .booking-doc-img img {
    border-radius: 4px;
    height: 90px;
    width: 90px;
    object-fit: cover;
  }
  
  .profile-det-info {
    overflow: hidden;
    font-weight: bolder;
  }
  
  .profile-det-info h3 {
    font-size: 17px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .dashboard-menu ul {
      color: #757575;
      font-size: 14px;
      line-height: 17px;
      list-style: none;
      margin: 0;
      padding: 0;
      text-transform: capitalize;
  }
  
  .dashboard-menu ul li {
      line-height: inherit;
  }
  
  .dashboard-menu>ul>li {
      border-bottom: 1px solid #f0f0f0;
      position: relative;
  }
  
  .dashboard-menu>ul>li:last-child {
      border-bottom: 0;
  }
  
  .dashboard-menu ul li a span,
  .dashboard-menu ul li a i {
      display: inline-block;
      vertical-align: middle;
  }
  
  .dashboard-menu>ul>li>a {
      color: #757575;
      display: block;
      padding: 16px 20px;
  }
  
  .dashboard-menu>ul>li:hover>a,
  .dashboard-menu>ul>li.active>a {
      color: #0de0fe;
  }
  
  .dashboard-menu ul li a i {
      font-size: 16px;
      margin-right: 10px;
      width: 16px;
  }