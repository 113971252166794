body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
Author       : HexaPi Tech
Project Name:  Tabiby - Doctor Finder
Version      : 0.0.1
*/

/*============================
 [Table of General CSS Across Project not for Components, Common between Patient,Doctors and Admin]

1. App General
2. Footer
3. Breadcrumb

========================================*/

/*-----------------
	1. App General
-----------------------*/

body{
  padding-top: 50px;
}
.content{
  background-color:#f8f9fa;
  min-height: 200px;
	padding: 30px 0 0;
}
#bg {
  background-color: #15558d!important;
}

.container-fluid {
	padding-left: 30px;
	padding-right: 30px;
}
@media only screen and (max-width: 1199px) {


	.container-fluid {
 
		padding-left: 20px;
		padding-right: 20px;
	}
}
@media only screen and (max-width: 767.98px) {
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
} 
/*-----------------
	2. Footer
-----------------------*/
.custom-footer {
  background-color: #15558d; 
  color: white; 
}
.footer-title{
  font-weight: bolder;
}
footer a{
color:white;
}

/*-----------------
	3. BreadCrumb
-----------------------*/
.breadcrumb-bar {
	background-color: #15558d;
	padding: 20px 10px 15px 10px;
}

.breadcrumb-bar .breadcrumb-title {
	color: #fff;
	font-size: 22px;
	font-weight: 700;
	margin: 5px 0 0;
  border-color: #15558d;
}
.breadcrumb-bar {
  height: auto;
}
.breadcrumb-bar .breadcrumb-title {
  font-size: 18px;
}
.page-breadcrumb ol {
	background-color: transparent;
	font-size: 12px;
	margin-bottom: 0;
	padding: 0;
}

.page-breadcrumb ol li a {
	color: #fff;
}

.page-breadcrumb ol li.active {
	color: #fff;
}

.page-breadcrumb .breadcrumb-item+.breadcrumb-item:before {
	color: #fff;
	font-size: 10px;
}
/* New Style */
.profile-sidebar {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  margin-bottom: 30px;
  overflow: hidden;
}

@media only screen and (max-width: 767.98px) {
  .profile-sidebar {
    margin-bottom: 20px;
  }
}

.pro-widget-content {
  padding: 0 0 20px;
}

.booking-date li {
  font-size: .875rem;
}

.booking-date li span {
  font-size: .875rem;
}

.widget-profile .profile-info-widget .booking-doc-img {
  padding: 0;
}

.booking-doc-info .booking-doc-img {
  width: 80px;
  margin-right: 15px;
}

.booking-doc-info .booking-doc-img img {
  border-radius: 4px;
  height: 80px;
  width: 80px;
  object-fit: cover;
}

.widget-profile .profile-info-widget .booking-doc-img img {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

.widget-profile {
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
}

.widget-profile .profile-info-widget {
  display: block;
  text-align: center;
}

.widget-profile .profile-info-widget .booking-doc-img {
  display: inline-block;
  margin: 0 0 15px;
  width: auto;
  padding: 8px;
  background-color: #f7f7f7;
  border-radius: 50%;
}

.widget-profile .profile-info-widget .booking-doc-img img {
  border-radius: 50%;
  height: 120px;
  width: 120px;
}

.booking-doc-img {
  width: 75px;
}

.booking-doc-img img {
  height: 75px;
  width: 75px;
}

.booking-doc-img {
  width: 70px;
}

.booking-doc-img img {
  height: 70px;
  width: 70px;
}

.profile-info-widget .booking-doc-img {
  margin: 0 0 15px;
}

.profile-info-widget .booking-doc-img img {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

.booking-doc-img {
  width: 80px;
  margin-right: 15px;
}

.pro-widget-content {
  border-bottom: 1px solid #f0f0f0;
  padding: 20px;
  text-align: center;
}

.profile-info-widget {
  display: flex;
  text-align: left;
}

.profile-info-widget .booking-doc-img {
  margin-right: 15px;
}

.profile-info-widget .booking-doc-img img {
  border-radius: 4px;
  height: 90px;
  width: 90px;
  object-fit: cover;
}

.profile-det-info {
  overflow: hidden;
  font-weight: bolder;
}

.profile-det-info h3 {
  font-size: 17px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dashboard-menu ul {
	color: #757575;
	font-size: 14px;
	line-height: 17px;
	list-style: none;
	margin: 0;
	padding: 0;
	text-transform: capitalize;
}

.dashboard-menu ul li {
	line-height: inherit;
}

.dashboard-menu>ul>li {
	border-bottom: 1px solid #f0f0f0;
	position: relative;
}

.dashboard-menu>ul>li:last-child {
	border-bottom: 0;
}

.dashboard-menu ul li a span,
.dashboard-menu ul li a i {
	display: inline-block;
	vertical-align: middle;
}

.dashboard-menu>ul>li>a {
	color: #757575;
	display: block;
	padding: 16px 20px;
}

.dashboard-menu>ul>li:hover>a,
.dashboard-menu>ul>li.active>a {
	color: #0de0fe;
}

.dashboard-menu ul li a i {
	font-size: 16px;
	margin-right: 10px;
	width: 16px;
}
.navbar {
  background-color: #fff;
}

.div-row {
  width: 100;
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.div-row .div-col-md,
.div-row .div {
  box-sizing: border-box;
  padding: 0 20px;
  width: 50%;
}

.div-row .login-banner-png {
  width: 100%;
}

.div-row .div {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 25px;
}

.heading-forgot {
  color: #15558d;
}

.login-form {
  width: 100%;
}

;

.div-row .div-login-header {
  width: 100%;
}

.div-row .button,
.div-row .button2 {
  justify-content: center;
  width: 100%;
  padding: 9px 0;
  text-align: center;
}

.div-row .text-wrapper-2,
.div-row .text-wrapper-3 {
  text-align: center;
  width: 100%;
  white-space: normal;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .div-row .div {
    width: 100%;
  }

  ;

  .div-row .div-col-md {
    display: none;
  }

  .login-banner-png {
    display: none;
  }

  /* editing resp */
  .div-row {
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0%;
    padding-top: 20px;
    padding-bottom: 20px;

  }

  ;

  .login-form {
    width: 100%;
  }

  ;
}
/* New Style */
.profile-sidebar {
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    margin-bottom: 30px;
    overflow: hidden;
  }
  
  @media only screen and (max-width: 767.98px) {
    .profile-sidebar {
      margin-bottom: 20px;
    }
  }
  
  .pro-widget-content {
    padding: 0 0 20px;
  }
  
  .booking-date li {
    font-size: .875rem;
  }
  
  .booking-date li span {
    font-size: .875rem;
  }
  
  .widget-profile .profile-info-widget .booking-doc-img {
    padding: 0;
  }
  
  .booking-doc-info .booking-doc-img {
    width: 80px;
    margin-right: 15px;
  }
  
  .booking-doc-info .booking-doc-img img {
    border-radius: 4px;
    height: 80px;
    width: 80px;
    object-fit: cover;
  }
  
  .widget-profile .profile-info-widget .booking-doc-img img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
  }
  
  .widget-profile {
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .widget-profile .profile-info-widget {
    display: block;
    text-align: center;
  }
  
  .widget-profile .profile-info-widget .booking-doc-img {
    display: inline-block;
    margin: 0 0 15px;
    width: auto;
    padding: 8px;
    background-color: #f7f7f7;
    border-radius: 50%;
  }
  
  .widget-profile .profile-info-widget .booking-doc-img img {
    border-radius: 50%;
    height: 120px;
    width: 120px;
  }
  
  .booking-doc-img {
    width: 75px;
  }
  
  .booking-doc-img img {
    height: 75px;
    width: 75px;
  }
  
  .booking-doc-img {
    width: 70px;
  }
  
  .booking-doc-img img {
    height: 70px;
    width: 70px;
  }
  
  .profile-info-widget .booking-doc-img {
    margin: 0 0 15px;
  }
  
  .profile-info-widget .booking-doc-img img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
  }
  
  .booking-doc-img {
    width: 80px;
    margin-right: 15px;
  }
  
  .pro-widget-content {
    border-bottom: 1px solid #f0f0f0;
    padding: 20px;
    text-align: center;
  }
  
  .profile-info-widget {
    display: flex;
    text-align: left;
  }
  
  .profile-info-widget .booking-doc-img {
    margin-right: 15px;
  }
  
  .profile-info-widget .booking-doc-img img {
    border-radius: 4px;
    height: 90px;
    width: 90px;
    object-fit: cover;
  }
  
  .profile-det-info {
    overflow: hidden;
    font-weight: bolder;
  }
  
  .profile-det-info h3 {
    font-size: 17px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .dashboard-menu ul {
      color: #757575;
      font-size: 14px;
      line-height: 17px;
      list-style: none;
      margin: 0;
      padding: 0;
      text-transform: capitalize;
  }
  
  .dashboard-menu ul li {
      line-height: inherit;
  }
  
  .dashboard-menu>ul>li {
      border-bottom: 1px solid #f0f0f0;
      position: relative;
  }
  
  .dashboard-menu>ul>li:last-child {
      border-bottom: 0;
  }
  
  .dashboard-menu ul li a span,
  .dashboard-menu ul li a i {
      display: inline-block;
      vertical-align: middle;
  }
  
  .dashboard-menu>ul>li>a {
      color: #757575;
      display: block;
      padding: 16px 20px;
  }
  
  .dashboard-menu>ul>li:hover>a,
  .dashboard-menu>ul>li.active>a {
      color: #0de0fe;
  }
  
  .dashboard-menu ul li a i {
      font-size: 16px;
      margin-right: 10px;
      width: 16px;
  }
