.navbar {
  background-color: #fff;
}

.div-row {
  width: 100;
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.div-row .div-col-md,
.div-row .div {
  box-sizing: border-box;
  padding: 0 20px;
  width: 50%;
}

.div-row .login-banner-png {
  width: 100%;
}

.div-row .div {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  flex-direction: column;
  gap: 20px;
  padding: 25px;
}

.heading-forgot {
  color: #15558d;
}

.login-form {
  width: 100%;
}

;

.div-row .div-login-header {
  width: 100%;
}

.div-row .button,
.div-row .button2 {
  justify-content: center;
  width: 100%;
  padding: 9px 0;
  text-align: center;
}

.div-row .text-wrapper-2,
.div-row .text-wrapper-3 {
  text-align: center;
  width: 100%;
  white-space: normal;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .div-row .div {
    width: 100%;
  }

  ;

  .div-row .div-col-md {
    display: none;
  }

  .login-banner-png {
    display: none;
  }

  /* editing resp */
  .div-row {
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0%;
    padding-top: 20px;
    padding-bottom: 20px;

  }

  ;

  .login-form {
    width: 100%;
  }

  ;
}